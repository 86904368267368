<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.prevent_pickup") }}
      <v-spacer></v-spacer>

      <v-btn color="error" @click="cancel" class="mr-2">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="success" @click="confirm" :disabled="isDisabledBtn">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            class="c-input-small input-disabled-bold"
            v-model.number="add_minutes"
            :items="addMinuteOptions"
            :label="$t('labels.prevent_pickup_add_minutes')"
            outlined
            dense
            hide-details
            single-line
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="codes"
            class="c-input-small"
            rows="5"
            label="Tracking | Mã đơn | Mã vận chuyển"
            placeholder="Tracking1;Tracking2;Tracking3;Tracking4;..."
            autofocus
            dense
            outlined
            clearable
            hide-details
            @paste.prevent.stop="onUidPaste"
          ></v-textarea>
        </v-col>
        <v-col
          cols="9"
          v-if="wrongCodes.length > 0"
          class="error--text font-italic"
        >
          Có: {{ wrongCodes.length }} đơn không thể gia hạn xử lý
        </v-col>
        <v-col cols="3" v-if="wrongCodes.length > 0" class="text-right">
          <div ref="copyRef" style="display: none; white-space: pre">
            <span v-html="wrongCodes.join('\n')"></span>
          </div>

          <span
            @click="copyCode"
            class="cursor-pointer primary--text text-decoration-underline"
          >
            Copy
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { PREVENT_PICKUP_TIMES } from "@/libs/const";

export default {
  name: "PreventPickupMultiple",
  data: () => ({
    codes: "",
    add_minutes: null,
    wrongCodes: [],
    isLoading: false,
    addMinuteOptions: [...PREVENT_PICKUP_TIMES],
  }),
  computed: {
    isDisabledBtn() {
      return !this.codes || !this.add_minutes;
    },
  },
  mounted() {},
  methods: {
    onUidPaste(evt) {
      const value = evt.clipboardData.getData("text");
      if (value.includes("\n")) {
        const codeSplit = value.split("\n").filter((v) => v);
        this.codes = codeSplit.join(";");
      } else if (value.includes(" ")) {
        const codeSplit = value.split(" ").filter((v) => v);
        this.codes = codeSplit.join(";");
      } else {
        this.codes += value;
      }
    },
    copyCode() {
      this.copyText(this.$refs.copyRef.textContent);
      this.$vToastify.success(this.$t("messages.copy_success"));
    },
    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.codes = "";
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const codes = this.codes.split(";");
      try {
        const { data } = await httpClient.post(
          "/orders-prevent-pickup-multiple",
          {
            codes,
            add_minutes: this.add_minutes,
          }
        );
        this.isLoading = false;

        for (let i = 0; i < codes.length; i++) {
          const code = codes[i];
          const order = [...data].find((od) =>
            [
              od.tracking_id,
              od.customer_order_id,
              od.delivery_order_id,
            ].includes(code)
          );
          if (!order) {
            this.wrongCodes.push(code);
          }
        }

        this.$vToastify.success(this.$t("messages.prevent_pickup_success"));

        if (this.wrongCodes.length === 0) {
          this.cancel();
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.codes = "";
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
